import { createApp } from 'vue'
import { createPinia } from 'pinia'

import vSelect from 'vue-select'
import Toast from 'vue-toastification'
import Datepicker from '@vuepic/vue-datepicker'
import VueQRCodeComponent from 'vue-qrcode-component'

// styles
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/styles/tailwind.css'
import 'vue-toastification/dist/index.css'
import 'vue-select/dist/vue-select.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'animate.css/animate.css'

// mouting point for the whole app
import App from '@/App.vue'
import { router } from '@/services'

const pinia = createPinia()
const app = createApp(App)

// set up components
app.component('qr-code', VueQRCodeComponent)
app.component('v-select', vSelect)
app.component('Datepicker', Datepicker)

app
  .use(pinia)
  .use(router)
  .use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 20,
    newestOnTop: true
  })
  .mount('#app')
