import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores'

// layouts
import Admin from '@/layouts/Admin.vue'
import Auth from '@/layouts/Auth.vue'

// views for Admin layout
import Dashboard from '@/views/admin/Dashboard.vue'
import Programs from '@/views/admin/programs/Programs.vue'

import Settings from '@/views/admin/Settings.vue'
import Tables from '@/views/admin/Tables.vue'

// views for Auth layout
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'

// views without layouts
import Home from '@/views/Home.vue'

// routes
const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: Auth,
    children: [
      {
        path: '/auth/login',
        component: Login,
        meta: { requiresAuth: false }
      },
      {
        path: '/auth/register',
        component: Register,
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    component: Admin,
    children: [
      {
        path: '/admin/dashboard',
        component: Dashboard,
        name: 'Dashboard',
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/programs',
        component: Programs,
        name: 'Programs',
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/companies',
        component: Tables,
        name: 'Companies',
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/users',
        component: Tables,
        name: 'Users',
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/profile',
        component: Settings,
        name: 'Profile',
        meta: { requiresAuth: true }
      }
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.meta.requiresAuth
  const auth = useAuthStore()

  if (authRequired && !auth.user) {
    auth.returnUrl = to.fullPath
    return '/auth/login'
  }
})
