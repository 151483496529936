<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <programs-table />
    </div>
  </div>
</template>
<script>
import ProgramsTable from './ProgramsTable.vue'

export default {
  components: {
    ProgramsTable
  }
}
</script>
