<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20" style="transform: translateZ(0)">
      <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div v-if="hasSponsors" class="flex flex-wrap text-center lg:text-center justify-center">
        <div class="w-full px-4 flex py-5 text-center justify-center" style="border: 0px solid red">
          <span class="mb-3 text-sm text-blueGray-500 font-semibold py-1 flex text-center">Sponsors</span><br />
          <img v-for="(i, idx) in sponsorImgs" :key="idx" :alt="i.name" :src="i.logo" :style="i.style" class="mt-5" />
        </div>
      </div>
      <!-- <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full px-4 flex py-5 justify-center" style="border: 0px solid red">
          <img alt="..." :src="logo" class="rounded-full h-auto align-middle border-none -ml-20 lg:-ml-16 max-w-150-px" />
        </div>
      </div> -->
      <hr class="my-1 border-blueGray-300" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }} <a href="https://www.presentationplusgh.com" class="text-blueGray-500 hover:text-blueGray-800"> Presentation Plus</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import is from 'is_js'
import { EndPoints } from '../../api/api'
import logo from '@/assets/img/logo.png'

export default {
  props: ['sponsors'],
  computed: {
    hasSponsors: function () {
      return is.array(this.sponsors) && this.sponsors.length > 0
    },
    sponsorImgs: function () {
      return this.sponsors.map((e) => {
        let style =
          e.name == 'United Nations Office on Drugs and Crime'
            ? 'margin-top: 40px; margin-right: 40px; width: 388px; height: 100px'
            : 'margin-top: 40px; margin-right: 40px; width: 100px; height: 100px'
        return { logo: EndPoints.FILE_IMAGE_URL + e.logo, name: e.name, style: style }
      })
    }
  },
  data() {
    return {
      date: new Date().getFullYear(),
      logo
    }
  }
}
</script>
