<template>
  <div>
    <main class="profile-page">
      <section class="relative block h-500-px">
        <div class="absolute top-0 w-full h-full bg-blueGray-800 bg-center bg-cover" :style="`background-image: url('${registerBg2}');`">
          <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" style="transform: translateZ(0)">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-5 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
            <div v-if="isLoading">
              <scale-loader size="50" color="#42ba93" style="text-align: left" class="mt-5"></scale-loader>
            </div>
            <div v-else class="px-6">
              <div class="flex flex-wrap justify-center">
                <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                  <div class="relative">
                    <img alt="..." :src="logo" class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px" />
                  </div>
                </div>
                <div v-if="!showSorry && useForm && source !== 'qrcode'" class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-right">
                  <div class="py-6 px-3 mt-3 sm:mt-1">
                    <qr-code :text="qrcodeUrl" :size="100" color="#42ba93" class="flex justify-center"></qr-code>
                  </div>
                </div>
              </div>
              <div v-if="!showSorry" class="py-10 text-center mt-12">
                <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">{{ title }}</h3>
                <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                  <i class="fas fa-calendar mr-2 text-lg text-blueGray-400"></i>
                  {{ isSameDay ? dateTime(program.startDate) : dateTime(program.startDate) + ' - ' + dateTime(program.endDate) }}
                </div>
                <div v-if="!useForm" class="mb-2 text-blueGray-600 mt-10 content-center">
                  <qr-code :text="qrcodeUrl" :size="200" color="#42ba93" class="flex justify-center"></qr-code>

                  <button
                    @click="useForm = true"
                    class="mt-12 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Register Online
                  </button>
                </div>
                <div v-else class="mb-2 text-blueGray-600 mt-10 flex justify-center">
                  <div class="w-full lg:w-8/12 px-4">
                    <RegistrationForm v-on:closeForm="useForm = false" :source="source" :programId="programId" />
                  </div>
                </div>
              </div>

              <div v-if="showSorry" class="mt-10 py-10 border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p v-if="!hasProgram" class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Sorry! The system cannot find the program you want to register for or the program is over. Please contact the administrator to help you with the correct link.
                      <br /><br />
                      Thanks!
                    </p>
                    <p v-else-if="isFull" class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Sorry! Registration is currently closed as all the available spaces are taken. Please contact the administrator to help you with your registration.
                      <br /><br />
                      Thanks!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component :sponsors="sponsors" />
  </div>
</template>
<script>
import is from 'is_js'
import moment from 'moment'
import { EndPoints } from '../api/api'

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import FooterComponent from '@/components/Footers/Footer.vue'
import RegistrationForm from '@/components/Forms/RegistrationForm.vue'

import companyLogo from '@/assets/img/logo.gif'
import registerBg2 from '@/assets/img/register_bg_2.png'
import programService from '../api/program'

export default {
  components: {
    ScaleLoader,
    RegistrationForm,
    FooterComponent
  },
  data() {
    return {
      registerBg2,
      companyLogo,
      program: null,
      programId: '',
      logo: '',
      title: 'Event Registration',
      qrcodeUrl: '',
      source: 'web',
      isLoading: true,
      isFull: false,
      useForm: false,
      sponsors: []
    }
  },
  computed: {
    showSorry: function () {
      return !this.hasProgram || this.isFull
    },
    hasProgram: function () {
      return is.not.null(this.program) && is.not.undefined(this.program) && is.existy(this.program.uuid)
    },
    isSameDay: function () {
      return is.not.null(this.program) && this.dateTime(this.program.startDate) == this.dateTime(this.program.endDate)
    }
  },
  created() {
    this.source = !is.undefined(this.$route.query.source) && is.not.empty(this.$route.query.source) ? this.$route.query.source : 'web'
    if (this.source == 'qrcode') {
      this.useForm = true
    }
    this.checkProgram()
  },
  methods: {
    checkProgram() {
      let self = this
      this.isLoading = true
      if (!is.undefined(this.$route.query.p) && !is.empty(this.$route.query.p)) {
        programService
          .getProgramByUuid(this.$route.query.p)
          .then((response) => {
            self.program = response
            self.programId = response.id
            self.sponsors = response.sponsors
            self.setDefaults(response.title, response.logo, response.company_logo)
            self.isFull = response.full
            self.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } else {
        this.logo = this.companyLogo
        this.isLoading = false
      }
    },
    setDefaults(pTitle, pLogo, cLogo) {
      this.title = is.not.empty(pTitle) ? pTitle : 'Event Registration'
      this.qrcodeUrl = window.location.origin + '?p=' + this.program.uuid + '&source=qrcode'
      this.logo = is.not.empty(pLogo) ? EndPoints.FILE_IMAGE_URL + pLogo : is.not.empty(cLogo) ? EndPoints.FILE_IMAGE_URL + cLogo : this.companyLogo
    },
    dateTime(value) {
      return moment(value).format('ddd, MMMM Do YYYY')
    }
  }
}
</script>
