const API_URL = process.env.VUE_APP_API_URL

export const endPoints = {
  FILE_IMAGE_URL: API_URL + 'view/image?file=',
  FILE_PDF_URL: API_URL + 'view/pdf?file=',
  PROGRAMS_URL: API_URL + 'programs',
  ATTENDEE_URL: API_URL + 'attendees',
  AUTH_LOGIN_URL: API_URL + 'login',
  AUTH_LOGOUT_URL: API_URL + 'signout',
  AUTH_RESET_URL: API_URL + 'reset',
  AUTH_RESET_EMAIL_URL: API_URL + 'forgot',
  ROLES_URL: API_URL + 'roles',
  USERS_URL: API_URL + 'users'
}
