<template>
  <VeeForm @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
    <div class="relative w-full mb-3">
      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email"> Email </label>
      <Field
        name="email"
        type="email"
        class="form-control border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        :class="{ 'is-invalid': errors.email }"
      />
      <div class="invalid-feedback text-red-500 text-xs text-left">{{ errors.email }}</div>
    </div>

    <div class="relative w-full mb-3">
      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="password"> Password </label>
      <Field
        name="password"
        type="password"
        class="form-control border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        :class="{ 'is-invalid': errors.password }"
      />
      <div class="invalid-feedback text-red-500 text-xs text-left">{{ errors.password }}</div>
    </div>

    <div class="text-center mt-6">
      <button
        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
        type="submit"
        :disabled="isSubmitting"
      >
        Sign In
      </button>
    </div>
    <!-- <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div> -->
  </VeeForm>
</template>

<script>
import * as yup from 'yup'
import { Form as VeeForm, Field } from 'vee-validate'
import { useAuthStore } from '@/stores'

export default {
  components: {
    VeeForm,
    Field
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().email('Must be a valid email').required('Email is required'),
      password: yup.string().required('Password is required').min(6, 'Password has to be at least 6 characters')
    })
    return {
      schema
    }
  },
  methods: {
    onSubmit(values, { setErrors }) {
      const authStore = useAuthStore()
      const { email, password } = values
      return authStore.login(email, password).catch((error) => {
        let eErr = JSON.stringify(error).toLowerCase().includes('email') ? 'Invalid email' : null
        let pErr = JSON.stringify(error).toLowerCase().includes('password') ? 'Invalid password' : null

        setErrors({ apiError: error, email: eErr, password: pErr })
      })
    }
  }
}
</script>
