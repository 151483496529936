import axios from 'axios'
import is from 'is_js'

export class ApiError extends Error {
  constructor(message, err) {
    super(message)
    this.name = 'ApiError'
    this.error = err
  }
}

const API_URL = process.env.VUE_APP_API_URL

const EndPoints = {
  FILE_IMAGE_URL: API_URL + 'view/image?file=',
  FILE_PDF_URL: API_URL + 'view/pdf?file=',
  PROGRAMS_URL: API_URL + 'programs',
  ATTENDEE_URL: API_URL + 'attendees',
  AUTH_LOGIN_URL: API_URL + 'login',
  AUTH_LOGOUT_URL: API_URL + 'signout',
  AUTH_RESET_URL: API_URL + 'reset',
  AUTH_RESET_EMAIL_URL: API_URL + 'forgot',
  ROLES_URL: API_URL + 'roles',
  USERS_URL: API_URL + 'users'
}

axios.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem('vuex')
    config.headers.post['Authorization'] = 'Bearer ' + token
    return config
  },
  function (error) {
    // Do something with request error
    throw new ApiError(error)
  }
)

export { EndPoints }
export default {
  name: 'apiService',
  reLogin(resp) {
    let m = is.existy(resp) && is.existy(resp.message) ? resp.message : ''
    if (m === 'Unauthenticated.') {
      window.location.href = '/auth'
    }
  },
  get(url, params = {}) {
    let self = this
    return axios
      .get(url, { params: params })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        self.reLogin(error)
        throw new ApiError(error, error)
      })
  },
  create(url, data) {
    let self = this
    return axios
      .post(url, data)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        self.reLogin(error)
        throw new ApiError(error, error)
      })
  },
  post(url, data) {
    let self = this
    return axios
      .post(url, data)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        self.reLogin(error)
        throw new ApiError(error, error)
      })
  },
  update(url, data) {
    let self = this
    return axios
      .put(url, data)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        self.reLogin(error)
        throw new ApiError(error, error)
      })
  },
  delete(url) {
    let self = this
    return axios
      .delete(url)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        self.reLogin(error)
        throw new ApiError(error, error)
      })
  }
}
