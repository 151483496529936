import { useToast } from 'vue-toastification'

export function useCapitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function useUcwords(str) {
  if (typeof str !== 'undefined') {
    return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase()
    })
  } else {
    return str
  }
}

export function useShowInfo(message) {
  const toast = useToast()
  toast.info(message, {
    position: 'top-right',
    timeout: 2349,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.1,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
  })
}

export function useShowSuccess(message) {
  const toast = useToast()
  toast.success(message, {
    position: 'top-right',
    timeout: 2349,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.1,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
  })
}

export function useShowWarning(message) {
  const toast = useToast()
  toast.error(message, {
    position: 'top-right',
    timeout: 2349,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.1,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
  })
}

export function useShowError(message) {
  const toast = useToast()
  toast.error(message, {
    position: 'top-right',
    timeout: 2349,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.1,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
  })
}
