<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
        <programs-bar-chart />
      </div>
      <div class="w-full xl:w-8/12 px-4">
        <attendance-line-chart />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 xl:mb-0 px-4">
        <top-programs-table />
      </div>
    </div>
  </div>
</template>
<script>
import AttendanceLineChart from '@/components/Charts/AttendanceLineChart.vue'
import ProgramsBarChart from '@/components/Charts/ProgramsBarChart.vue'
import TopProgramsTable from '@/components/Charts/TopProgramsTable.vue'
export default {
  name: 'dashboard-page',
  components: {
    AttendanceLineChart,
    ProgramsBarChart,
    TopProgramsTable
  }
}
</script>
