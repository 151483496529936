import { defineStore, acceptHMRUpdate } from 'pinia'
import { endPoints, fetchWrapper, router } from '@/services'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refresh_token'),
    user: JSON.parse(localStorage.getItem('user')),
    isAdmin: localStorage.getItem('is_admin') === 'true' ? true : false,
    isSuperAdmin: localStorage.getItem('is_super_admin') === 'true' ? true : false,
    returnUrl: null
  }),
  actions: {
    /**
     * Attempt to login a user
     * @param {string} email
     * @param {string} password
     */
    async login(email, password) {
      const data = await fetchWrapper.post(endPoints.AUTH_LOGIN_URL, { email, password })

      this.user = data.user
      this.token = data.token
      this.refreshToken = data.refresh
      this.isAdmin = ['super_admin', 'admin', 'company_admin'].includes(data.user.role)
      this.isSuperAdmin = ['super_admin', 'admin'].includes(data.user.role)

      localStorage.setItem('user', JSON.stringify(this.user))
      localStorage.setItem('token', this.token)
      localStorage.setItem('refresh_token', this.refreshToken)
      localStorage.setItem('is_admin', this.isAdmin)
      localStorage.setItem('is_super_admin', this.isSuperAdmin)

      router.push(this.returnUrl || '/admin')
    },
    logout() {
      this.$reset()
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      router.push('/auth/login')
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
