<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center py-3">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">Programs</h3>
        </div>
        <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            <i class="fas fa-plus text-white mr-2 text-sm"></i>

            Add Program
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto mt-2 pb-20">
      <!-- Projects table -->
      <table-lite :is-slot-mode="true" :is-loading="programs.loading" :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable">
        <template v-slot:action="data"> <programs-table-dropdown :is-admin="isAdmin" :data="data" /></template>
        <template v-slot:attendees="data">
          <div>
            <div class="flex items-center">
              <span class="mr-2">{{ data.value.attendees }}</span>
              <div v-if="data.value.limit !== 0" class="relative w-full">
                <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                  <div :style="'width: ' + data.value.progress + '%'" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
                </div>
              </div>
            </div>
            <br />
            <div v-if="data.value.limit !== 0" class="w-full text-xs" style="display: inline-block">Limit: {{ data.value.limit }}</div>
          </div>
        </template>

        <template v-slot:link="data"> <alink :url="data.value.reg_link" :text="data.value.reg_link" :show-copy="true" link-class="is-rows-el name-btn" target="_blank" /> </template>
      </table-lite>
    </div>
  </div>
</template>
<script>
import is from 'is_js'
import { mapState } from 'pinia'
import TableLite from 'vue3-table-lite'
import { useAuthStore, useProgramsStore } from '@/stores'
import { endPoints } from '@/services'

import Alink from '@/components/Alink.vue'
import ProgramsTableDropdown from './ProgramsTableDropdown.vue'

import bootstrap from '@/assets/img/bootstrap.jpg'
import team1 from '@/assets/img/team-1-800x800.jpg'

export default {
  props: {
    color: {
      default: 'light',
      validator: function (value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1
      }
    }
  },
  components: {
    TableLite,
    Alink,
    ProgramsTableDropdown
  },
  data() {
    return {
      bootstrap,
      team1,
      table: {
        isLoading: false,
        isReSearch: false,
        columns: [
          {
            label: 'Company',
            field: 'company',
            width: '20%',
            sortable: true,
            isKey: true,
            columnClasses: ['border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left flex items-center'],
            display: function (row) {
              return '<img src="' + row.company_logo + '" class="h-12 w-12 bg-white rounded-full border" alt="..." />' + '<span class="ml-3 font-bold text-blueGray-600">' + row.company + '</span>'
            }
          },
          {
            label: 'Title',
            field: 'title',
            width: '20%',
            sortable: true,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4']
          },
          {
            label: 'Date',
            field: 'start_date',
            width: '13%',
            sortable: true,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4']
          },
          {
            label: 'Attendees',
            field: 'attendees',
            width: '12%',
            sortable: true,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4']
          },
          {
            label: 'Logo',
            field: 'logo',
            width: '10%',
            sortable: false,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4'],
            display: function (row) {
              return '   <div class="flex"> \
                <img src="' + row.logo + '" alt="..." class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow" /> \
              </div>'
            }
          },
          {
            label: 'Agenda',
            field: 'agenda',
            width: '10%',
            sortable: false,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4'],

            display: function (row) {
              return '<a href="' + row.agenda + '" data-id="' + row.id + '" class="is-rows-el name-btn" target="_blank"> View </a>'
            }
          },
          {
            label: 'Registration Link',
            field: 'link',
            width: '10%',
            sortable: false,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4']
          },
          {
            label: '',
            field: 'action',
            width: '5%',
            sortable: false,
            columnClasses: ['border-t-0 px-6', 'align-middle', 'border-l-0', 'border-r-0', 'text-xs', 'p-4']
          }
        ],
        rows: [],
        totalRecordCount: 1,
        sortable: {
          order: 'company',
          sort: 'asc'
        },
        messages: {
          pagingInfo: 'Showing {0}-{1} of {2}',
          pageSizeChangeLabel: 'Row count:',
          gotoPageLabel: 'Go to page:',
          noDataAvailable: 'No data'
        }
      }
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isAdmin', 'isSuperAdmin']),
    ...mapState(useProgramsStore, ['programs'])
  },
  watch: {
    programs: function (v) {
      if (is.undefined(v.loading) && v.length > 0) {
        this.table.rows = v.map(function (e) {
          return {
            company: e.company.name,
            company_logo: endPoints.FILE_IMAGE_URL + e.company.logo,
            title: e.title,
            start_date: e.startDate,
            limit: e.limit,
            attendees: e.attendees.length,
            progress: e.limit === 0 ? 100 : Math.ceil((e.attendees.length / e.limit) * 100),
            logo: endPoints.FILE_IMAGE_URL + e.logo,
            agenda: endPoints.FILE_PDF_URL + e.pdf,
            reg_link: 'http://' + window.location.host + '?p=' + e.uuid,
            action: ''
          }
        })
        this.table.totalRecordCount = this.table.rows.length
      }
    }
  },
  created() {
    this.getPrograms()
  },
  methods: {
    getPrograms: function () {
      const programsStore = useProgramsStore()
      programsStore.getAll()
    }
  }
}
</script>
<style scoped>
::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  border-width: 0;
  border-style: solid;
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  padding: 1.5rem;
  white-space: normal;
  border: none;
}

::v-deep(.vtl-table td:first-child div) {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

::v-deep(.vtl-paging) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

::v-deep(.vtl-paging-info) {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
  text-transform: uppercase;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}
::v-deep(.vtl-paging-info div) {
  margin-top: 9px;
}
::v-deep(.vtl-paging-change-div) {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
  text-transform: uppercase;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

::v-deep(.vtl-paging-change-div select) {
  width: 15%;
  padding: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  color: rgba(100, 116, 139, 1);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}
::v-deep(.vtl-paging-pagination-page-link) {
  border: none;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}
</style>
