<template>
  <div v-on:mouseover="mouseover($event)" v-on:mouseleave="mouseleave($event)">
    <a :href="url" :class="linkClass" :target="target">{{ text }}</a>
    <div v-show="canCopy" class="text-indigo-600" style="display: inline-block; cursor: pointer" @click="copy" :class="copyClass">
      {{ copyLabel }}
    </div>
  </div>
</template>
<script>
import is from 'is_js'

export default {
  props: ['url', 'text', 'showCopy', 'linkClass', 'target'],
  computed: {
    canCopy: function () {
      return is.not.undefined(this.showCopy) && this.showCopy
    }
  },
  data() {
    return {
      copyLabel: '',
      copyClass: ''
    }
  },
  methods: {
    mouseover: function (event) {
      event.preventDefault()
      this.copyClass = 'animate__animated animate__fadeIn'
      this.copyLabel = 'Copy'
    },
    mouseleave: function (event) {
      event.preventDefault()
      this.copyClass = ''
      this.copyLabel = ''
    },
    copy: function () {
      const el = document.createElement('textarea')
      el.value = this.url
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
      this.copyClass = ''
      this.copyLabel = 'Copied'
      setTimeout(this.reset, 2500)
    },
    reset: function () {
      this.copyLabel = ''
      this.copyClass = ''
    }
  }
}
</script>
