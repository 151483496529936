import apiService, { EndPoints, ApiError } from './api'

export default {
  name: 'ProgramService',
  components: {
    apiService
  },

  getProgramByUuid(id) {
    let url = EndPoints.PROGRAMS_URL + '/uuid/' + id
    console.log('Calling ' + url)
    return apiService
      .get(url)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        throw new ApiError(error, error.error)
      })
  },

  addAttendee(data) {
    let url = EndPoints.ATTENDEE_URL
    return apiService
      .create(url, data)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        throw new ApiError(error, error.error)
      })
  }
}
