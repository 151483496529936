<template>
  <!-- Header -->
  <div class="relative bg-emerald-600" :class="[showCards ? 'pt-12 md:pt-32 pb-32' : 'pt-12 md:pt-12 pb-12']">
    <div v-if="showCards" class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div v-if="isSuperAdmin" class="w-full px-4" :class="[isSuperAdmin ? 'lg:w-6/12 xl:w-4/12 ' : 'lg:w-6/12 xl:w-4/12 ']">
            <card-stats
              statSubtitle="Companies"
              statTitle="350,897"
              statArrow="up"
              statPercent="3.48"
              statPercentColor="text-emerald-500"
              statDescripiron="Since last month"
              statIconName="far fa-building"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full px-4" :class="[isSuperAdmin ? 'lg:w-6/12 xl:w-4/12 ' : 'lg:w-6/12 xl:w-6/12 ']">
            <card-stats
              statSubtitle="Programs"
              statTitle="2,356"
              statArrow="down"
              statPercent="3.48"
              statPercentColor="text-red-500"
              statDescripiron="Since last week"
              statIconName="fas fa-calendar-alt"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full px-4" :class="[isSuperAdmin ? 'lg:w-6/12 xl:w-4/12 ' : 'lg:w-6/12 xl:w-6/12 ']">
            <card-stats
              statSubtitle="Attendees"
              statTitle="924"
              statArrow="down"
              statPercent="1.10"
              statPercentColor="text-orange-500"
              statDescripiron="Since yesterday"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores'
import CardStats from '@/components/Cards/CardStats.vue'

export default {
  components: {
    CardStats
  },
  computed: {
    ...mapState(useAuthStore, ['isSuperAdmin']),
    showCards: function () {
      return this.$route.name === 'Dashboard'
    }
  }
}
</script>
