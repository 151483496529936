import { defineStore } from 'pinia'
import { endPoints, fetchWrapper } from '@/services'

export const useProgramsStore = defineStore({
  id: 'programs',
  state: () => ({
    programs: {}
  }),
  actions: {
    async getAll() {
      this.programs = { loading: true }
      fetchWrapper
        .get(endPoints.PROGRAMS_URL)
        .then((programs) => (this.programs = programs))
        .catch((error) => (this.programs = { error }))
    }
  }
})
